import { BackgroundWrapper } from "../../atoms/backgroundWrapper/BackgroundWrapper";
import { Button } from "../../atoms/button/Button";
import { Text } from "../../atoms/text/Text";
import style from "./selectOption.module.scss";
import { Container } from "../../atoms/container/Container";
import { SelectPatient } from "../selectPatient/SelectPatient";
import { InsuranceCard } from "../../molecules/insuranceCard/InsuranceCard";
import { useSelectOPtion } from "../../../hooks/useSelectOPtion/UseSelectOption";
import { useLogisticsData } from "../../../hooks/uselogisitcData/UseLogisticsData";
import { LogisticsAddressTab } from "../logisticsAddressTab/LogisticsAddressTab";

export const SelectOption = () => {
  const {
    handleDemo,
    handleGeneralEnquiry,
    isBtnSelected,
    handleSelectInsurance,
    handleSelectHospitals,
    optionSelected,
    data,
    handleResolveTicket
  } = useSelectOPtion();
  const {
    isAddressSelected,
    selectedAddress,
    setSelectedAddress,
    handleAddressSelection,
    handleEditSelectedAddress,
    isHospitalSelected,
    selectedHospital,
    handleEditHospital,
    renderHospitalUi,
    value,
    handleIncreaseRadius,
    marks,
    patientAddress,
    hospitalLists,
    handleSelectHospital,
    handleStartEmergency,
   
  } = useLogisticsData();

  return (
    <>
      <div className={style.wrapper}>
        <BackgroundWrapper>
          <div className={style.flex}>
            <Text className={style.title} text="Select Option" />
            <div className={style.btn_flex}>
              <Button
                onClick={handleGeneralEnquiry}
                text="General Enquiry"
                className={
                  !isBtnSelected.demo ? style.btn : style.btn_dis_select
                }
              />
              <Button
                text="TestDemo"
                onClick={()=>handleDemo("Test Demo")}
                className={
                  !isBtnSelected.general ? style.btn : style.btn_dis_select
                }
              />
              <Button
                text="Customer Test"
                onClick={()=>handleDemo("Customer Test")}
                className={
                  !isBtnSelected.general ? style.btn : style.btn_dis_select
                }
              />
               <Button
                text="Internal Test"
                onClick={()=>handleDemo("Internal Test")}
                className={
                  !isBtnSelected.general ? style.btn : style.btn_dis_select
                }
              />
            </div>
            {isBtnSelected?.general && (
              <div className={style.btn_flex}>
                <Button
                  text="Insurance details"
                  onClick={handleSelectInsurance}
                  className={
                    !optionSelected.hospital
                      ? style.btn_option
                      : style.btn_option_disable
                  }
                />
                <Button
                  onClick={handleSelectHospitals}
                  text="Nearest Hospital"
                  className={
                    !optionSelected.insurance
                      ? style.btn_option
                      : style.btn_option_disable
                  }
                />
              </div>
            )}
          </div>
        </BackgroundWrapper>
        <BackgroundWrapper>
          <Container>
            <SelectPatient />
          </Container>
        </BackgroundWrapper>
        {optionSelected?.insurance && (
          <BackgroundWrapper>
            <Container>
              <InsuranceCard data={data?.insurancePolicies || []} />
            </Container>
          </BackgroundWrapper>
        )}
        {optionSelected?.hospital && (
          <BackgroundWrapper className={style.space}>
            <Container>
              <LogisticsAddressTab
                component="GeneralEnq"
                isAddressSelected={isAddressSelected}
                selectedAddress={selectedAddress}
                setSelectedAddress={setSelectedAddress}
                handleAddressSelection={handleAddressSelection}
                handleEditSelectedAddress={handleEditSelectedAddress}
                isHospitalSelected={isHospitalSelected}
                selectedHospital={selectedHospital}
                handleEditHospital={handleEditHospital}
                renderHospitalUi={renderHospitalUi}
                value={value}
                handleIncreaseRadius={handleIncreaseRadius}
                marks={marks}
                patientAddress={patientAddress}
                hospitalLists={hospitalLists}
                handleSelectHospital={handleSelectHospital}
                handleStartEmergency={handleStartEmergency}
              />
            </Container>
          </BackgroundWrapper>
        )}

        <div>
        {(optionSelected?.hospital || optionSelected?.insurance) && (
          <Button onClick={handleResolveTicket} text="Resolve Emergency" className={style.btn}/>
        )}
        </div>

      </div>
    </>
  );
};
